import * as React from "react"
import { PageProps } from "gatsby"

import ScheduleACall from "./schedule-a-call"
import Logo from "./logo"

const Header: React.FC<PageProps> = () => (
  <header className="fixed w-full bg-white filter shadow-header z-50">
    <div className="container max-w-screen-xl mx-auto p-4 flex gap-10 justify-between">
      <Logo />
      <ScheduleACall header={true} />
    </div>
  </header>
)

export default Header
