import React, { useRef, useLayoutEffect } from "react"

import { SVGData } from "../utils/types"

const SVG: React.FC<SVGData> = ({
  image,
  className,
  svgClass,
  headlineClass,
  copyClass,
  title,
  copy,
}) => {
  const SVGContainerRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (SVGContainerRef) {
      if (svgClass) {
        SVGContainerRef?.current?.firstElementChild?.classList.add(svgClass)
      }
    }
  })

  return (
    <div className={className}>
      <div
        ref={SVGContainerRef}
        dangerouslySetInnerHTML={{ __html: image.image }}
      />
      {title && <h5 className={headlineClass}>{title}</h5>}
      {copy && (
        <div
          className={copyClass}
          dangerouslySetInnerHTML={{ __html: copy.childMarkdownRemark.html }}
        />
      )}
    </div>
  )
}

export default SVG
