import * as React from "react"

const Container: React.FC<{ section?: boolean }> = ({
  section = true,
  children,
}) => {
  const sectionClass =
    "container mx-auto flex flex-col gap-5 md:gap-9 p-5 max-w-screen-xl"

  if (section) {
    return <section className={sectionClass}>{children}</section>
  }

  return <div className={sectionClass}>{children}</div>
}

export default Container
