import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import SVG from "./svg"
import { SVGData } from "../utils/types"

type SVGEdgeData = {
  node: SVGData
}

export type LogoData = {
  allContentfulSvg: {
    edges: SVGEdgeData[]
  }
}

const Logo: React.FC<{ header?: boolean }> = ({ header = true }) => {
  const {
    allContentfulSvg: { edges },
  }: LogoData = useStaticQuery(graphql`
    query MyQuery {
      allContentfulSvg(filter: { name: { glob: "NMG Logo*" } }) {
        edges {
          node {
            id
            contentful_id
            image {
              id
              image
            }
          }
        }
      }
    }
  `)

  const loadSvg = (): SVGData => {
    const svgId: string = header
      ? "3WcEYTDRx3uAjEypzIuBFY"
      : "5q56kTex3QMoWdy3UFIxlH"

    const edge = edges.find(
      ({ node: { contentful_id } }) => contentful_id === svgId
    )

    if (edge == undefined) {
      return edges[0].node
    }

    return edge.node
  }

  const contentfulSvg: SVGData = loadSvg()

  return (
    <Link to="/">
      <SVG className="w-48 md:w-60" svgClass="fill-white" {...contentfulSvg} />
    </Link>
  )
}

export default Logo
