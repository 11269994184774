import * as React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"

type ButtonType = {
  contentfulButton: {
    callToAction: string
  }
}

const ScheduleACall: React.FC<{ header: boolean; reverse?: boolean }> = ({
  header,
  reverse = false,
}) => {
  const {
    contentfulButton: { callToAction },
  }: ButtonType = useStaticQuery(graphql`
    query ButtonQuery {
      contentfulButton(contentful_id: { eq: "12sa1w4y93m4pASIFQkTNd" }) {
        callToAction
      }
    }
  `)
  let buttonClass =
    "hover:ring-1 font-headline font-semibold rounded text-center uppercase"

  if (reverse) {
    buttonClass += " bg-white text-action"
  } else {
    buttonClass += " bg-action text-white"
  }

  if (header) {
    // do something with className
    buttonClass += " p-1 md:text-xl md:px-5 md:py-3"
  } else {
    buttonClass += " text-2xl py-3 px-7 m-5 mx-auto"
  }

  return (
    <Link className={buttonClass} to="/#schedule-a-call">
      {callToAction}
    </Link>
  )
}

export default ScheduleACall
