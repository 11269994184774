import * as React from "react"
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"

import Logo from "./logo"
import Container from "./container"

import FooterCurve from "../svgs/section-top-footer.svg"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { FuildObject } from "gatsby-image"

type FooterData = {
  policies: {
    nodes: [
      {
        slug: string
        linkText: string
      }
    ]
  }
  background: {
    childImageSharp: {
      fluid: FuildObject
    }
  }
}

const Footer: React.FC<PageProps> = () => {
  const { policies, background }: FooterData = useStaticQuery(graphql`
    {
      policies: allContentfulPolicy {
        nodes {
          slug
          linkText
        }
      }
      background: file(relativePath: { eq: "bkgrnd-abstract-blue.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      Tag="footer"
      className="bg-cover"
      fluid={background.childImageSharp.fluid}
    >
      <FooterCurve className="w-full fill-blue" />
      <Container>
        <Logo header={false} />
        <div>
          <p className="text-white">
            13702 S. 200 W. Ste: #B-7
            <br />
            Draper, UT 84020
          </p>
          <a className="text-white" href="tel:+8019932222">
            1.801.933.2222
          </a>
        </div>
        <div className="flex divide-x gap-3 divide-nmg-grey divide-solid">
          {policies.nodes.map(({ slug, linkText }) => (
            <Link
              className="text-nmg-blue pl-3 first:pl-0"
              key={slug}
              to={`/${slug}`}
            >
              {linkText}
            </Link>
          ))}
        </div>
        <span className="text-white text-sm">
          &copy; {new Date().getFullYear()} Net Media Group, LLC. All Rights
          Reserved.
        </span>
      </Container>
    </BackgroundImage>
  )
}

export default Footer
